import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import Input from './componentsFinance/common/Input'
import Button from './componentsFinance/common/Button'
import Checkbox from './common/Checkbox'
import { domain } from '../utils/request'
import { validateFile } from '../utils/validators'

export default function BalanceItemForm({ onCancel, loading, initialValues, rotationId, finance, onSubmit }) {
    const [file, setFile] = useState(null)

    async function onSuccess(data) {
        const newData = new FormData()
        if (file) {
            newData.append('balancePdf', file, file.name)
        }
        newData.append('finance', finance.id)
        newData.append('balance', rotationId)
        newData.append('balanceComment', data.balanceComment)
        newData.append('inps', data.inps)
        newData.append('tax', data.tax)

        onSubmit(newData)
    }

    return (
        <Formik onSubmit={onSuccess} initialValues={{ balanceComment: '',
            tax: false,
            inps: false,
            ...initialValues,
            balancePdf: initialValues && !isEmpty(initialValues.balancePdf) ? initialValues.balancePdf : '' }}
            validate={validateFile('balancePdf')}>
            {({
                setFieldValue, errors, touched,
            }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <Input
                                name="balanceComment"
                                label="Комментарий"
                                disabled={loading}
                                placeholder="Комментарий" />
                        </div>

                    </div>
                    <Checkbox
                        name="tax"
                        label="Солиқлар тўланиши"
                        disabled={loading}
                        placeholder="Комментарий" />

                    <Checkbox
                        name="inps"
                        label="ИНПС реестр"
                        disabled={loading}
                        placeholder="Комментарий" />


                    <div className="columns">
                        <div className="column">
                            <p className="level-left">Молиявий Баланс</p>
                            <input
                                name="balancePdf"
                                onChange={(e) => {
                                    setFieldValue('balancePdf', e.target.value)
                                    setFile(e.target.files[0])
                                }}
                                type="file"
                                disabled={loading} />
                            {errors.balancePdf && touched.balancePdf && (
                                <div className="has-text-danger">{errors.balancePdf}</div>
                            )}

                            {initialValues ? (domain + initialValues.balancePdf).split('/').pop() : ''}
                        </div>

                    </div>

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
