import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import Input from './componentsFinance/common/Input'
import Button from './componentsFinance/common/Button'
import Checkbox from './common/Checkbox'
import { domain } from '../utils/request'
import { validateFile } from '../utils/validators'

export default function QqcMonthlyItemForm({ onCancel, loading, initialValues, qqcMonthlyId, finance, onSubmit }) {
    const [file, setFile] = useState(null)

    async function onSuccess(data) {
        const newData = new FormData()
        if (file) {
            newData.append('qqcMonthlyPdf', file, file.name)
        }

        newData.append('finance', finance.id)
        newData.append('qqcMonthly', qqcMonthlyId)
        newData.append('qqcMonthlyComment', data.qqcMonthlyComment)
        newData.append('tax', data.tax)
        newData.append('inps', data.inps)
        onSubmit(newData)
    }

    return (
        <Formik
            onSubmit={onSuccess}
            initialValues={{
                qqcMonthlyComment: '',
                tax: false,
                inps: false,
                ...initialValues,
                qqcMonthlyPdf: initialValues && !isEmpty(initialValues.qqcMonthlyPdf) ? initialValues.qqcMonthlyPdf : '',
            }}
            validate={validateFile('qqcMonthlyPdf')}
        >
            {({ setFieldValue, errors, touched }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <Input name="qqcMonthlyComment" label="Комментарий" disabled={loading}
                                placeholder="Комментарий" />
                        </div>
                    </div>
                    <Checkbox name="tax" label="Солиқлар тўланиши" disabled={loading} placeholder="Комментарий" />
                    <div className="columns">
                        <div className="column">
                            <p className="level-left">ҚҚС Ойлик</p>
                            <input
                                onChange={(e) => {
                                    setFieldValue('qqcMonthlyPdf', e.target.value)
                                    setFile(e.target.files[0])
                                }}
                                disabled={loading}
                                name="qqcMonthlyPdf"
                                type="file"
                            />
                            {errors.qqcMonthlyPdf && touched.qqcMonthlyPdf && (
                                <div className="has-text-danger">{errors.qqcMonthlyPdf}</div>
                            )}
                            {initialValues ? (domain + initialValues.qqcMonthlyPdf).split('/').pop() : 'Файл юкланмаган'}
                        </div>
                    </div>
                    <Button loading={loading} text="Сохранить" type="submit" icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;
                    <Button onClick={onCancel} icon="ion-md-close" text="Отмена" className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
