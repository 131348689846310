import React, { useState } from 'react'

import Select from 'react-select'
import { RESULT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import ZeroTabs from '../components/ZeroTabs'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import ResultItem from '../components/ResultItem'
import ResultCreate from '../components/ResultCreate'
import { currentLastMonth, getMonth } from '../utils/date'

export default function Result() {
    const [month, setMonth] = useState(currentLastMonth)

    const [page, setPage] = useState(1)
    const report = useLoad({ url: RESULT_LIST })
    const reportItem = report.response ? report.response.results : []

    const [showModal, hideModal] = useModal(
        <ResultCreate
            onSuccess={() => {
                hideModal()
                report.request()
            }}
            onCancel={() => hideModal()}
        />,
    )

    const filterData = reportItem.filter((item) => item.date.slice(0, 7) === month.slice(0, 7) || month === 'все')
    const options = reportItem.map((item) => ({ value: item.date, label: getMonth(item.date) }))
    options.unshift({ value: 'все', label: 'Все' })
    const handleMonthChange = (selectedOption) => {
        setMonth(selectedOption.value)
    }

    return (
        <Layout>
            <div style={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10, backgroundColor: 'white' }}>
                <ZeroTabs />
                <div className="columns mb-4 mr-3">
                    <div className="column is-capitalized ml-4">
                        <span className="is-size-4">Молиявий Натижа:</span>
                    </div>
                    <div className="column mt-3">
                        <div className="columns is-pulled-right">
                            <Button icon="ion-md-calendar" text="Янги ой қўшиш" onClick={showModal}
                                className="button is-success is-outlined" />
                        </div>
                        <div className="columns is-pulled-right mr-5" style={{ minWidth: '200px' }}>
                            <Select
                                defaultValue={{ value: month, label: getMonth(month) }}
                                options={options}
                                onChange={handleMonthChange}
                                placeholder="Ойни танланг"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: '#d1d5db',
                                        borderRadius: '8px',
                                        padding: '2px',
                                        width: '200px',
                                        cursor: 'pointer',
                                    }),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Table
                loading={report.loading}
                items={filterData}
                columns={{
                    id: '№',
                    name: 'Ой номи',
                    stir: 'Мижозлар',
                    directorStir: 'Ҳисоботлар жўнатилиши',
                    ssuploadRotation: 'Солиқлар тўланиши',
                    ad: '',
                    uploadRotation: '',
                    assd: '',
                }}
                totalCount={report.response ? report.response.count : 0}
                pageSize={10}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item, index) => (
                    <ResultItem
                        reportItem={item}
                        index={index}
                        key={item.id}
                        onUpdate={report.request}
                        onDelete={report.request}
                        request={report.request}
                        reportItems={reportItem.response ? reportItem.response.results : []} finance={item}
                    />
                )}
            />
        </Layout>
    )
}
