import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import Input from './componentsFinance/common/Input'
import Button from './componentsFinance/common/Button'
import { domain } from '../utils/request'
import { validateFile } from '../utils/validators'

export default function ManthlyQqcMonthlyItemForm({ onCancel, loading, initialValues, qqcMonthlyId, finance, onSubmit }) {
    const [file, setFile] = useState(null)

    async function onSuccess(data) {
        const newData = new FormData()
        if (file) {
            newData.append('qqcMonthlyPdf', file, file.name)
        }
        newData.append('finance', finance.id)
        newData.append('qqcMonthly', qqcMonthlyId)
        newData.append('qqcMonthlyComment', data.qqcMonthlyComment)

        onSubmit(newData)
    }

    return (
        <Formik onSubmit={onSuccess} initialValues={{
            qqcMonthlyComment: '',
            ...initialValues,
            qqcMonthlyPdf: initialValues && !isEmpty(initialValues.qqcMonthlyPdf) ? initialValues.qqcMonthlyPdf : '',
        }} validate={validateFile('qqcMonthlyPdf')}>
            {({
                setFieldValue, errors, touched,
            }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <Input
                                name="qqcMonthlyComment"
                                label="Комментарий"
                                disabled={loading}
                                placeholder="Комментарий" />
                        </div>

                    </div>

                    <div className="columns">
                        <div className="column">
                            <p className="level-left">
                            ҚҚС Ойлик
                            </p>
                            <input
                                name="pdf"
                                onChange={(e) => {
                                    setFieldValue('qqcMonthlyPdf', e.target.value)
                                    setFile(e.target.files[0])
                                }}
                                type="file"
                                disabled={loading} />
                            {errors.qqcMonthlyPdf && touched.qqcMonthlyPdf && (
                                <div className="has-text-danger">{errors.qqcMonthlyPdf}</div>
                            )}
                            {initialValues ? (domain + initialValues.qqcMonthlyPdf).split('/').pop() : ''}
                        </div>

                    </div>

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
