import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import Input from './componentsFinance/common/Input'
import Button from './componentsFinance/common/Button'
import Checkbox from './common/Checkbox'
import { domain } from '../utils/request'
import { validateFile } from '../utils/validators'


export default function ManthlyQqcQuarterlyItemForm({
    onCancel,
    loading,
    initialValues,
    qqcQuarterlyId,
    finance,
    onSubmit,
}) {
    const [file, setFile] = useState(null)

    async function onSuccess(data) {
        const newData = new FormData()
        if (file) {
            newData.append('qqcQuarterlyPdf', file, file.name)
        }
        newData.append('finance', finance.id)
        newData.append('qqcQuarterly', qqcQuarterlyId)
        newData.append('qqcQuarterlyComment', data.qqcQuarterlyComment)
        newData.append('tax', data.tax)
        newData.append('inps', data.inps)

        onSubmit(newData)
    }


    return (
        <Formik onSubmit={onSuccess} initialValues={{
            qqcQuarterlyComment: '',
            tax: false,
            inps: false,
            ...initialValues,
            qqcQuarterlyPdf: initialValues && !isEmpty(initialValues.qqcQuarterlyPdf) ? initialValues.qqcQuarterlyPdf : '',
        }} validate={validateFile('qqcQuarterlyPdf')}>
            {({
                setFieldValue, touched, errors,
            }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <Input
                                name="qqcQuarterlyComment"
                                label="Комментарий"
                                disabled={loading}
                                placeholder="Комментарий" />
                        </div>

                    </div>

                    <Checkbox
                        name="tax"
                        label="Солиқлар тўланиши"
                        disabled={loading}
                        placeholder="Комментарий" />

                    <Checkbox
                        name="inps"
                        label="ИНПС реестр"
                        disabled={loading}
                        placeholder="Комментарий" />


                    <div className="columns">
                        <div className="column">
                            <p className="level-left">
                                ҚҚС Кварталлик
                            </p>

                            <input
                                name="qqcQuarterlyPdf"
                                onChange={(e) => {
                                    setFieldValue('qqcQuarterlyPdf', e.target.value)
                                    setFile(e.target.files[0])
                                }}
                                type="file"
                                disabled={loading} />

                            {errors.qqcQuarterlyPdf && touched.qqcQuarterlyPdf && (
                                <div className="has-text-danger">{errors.qqcQuarterlyPdf}</div>
                            )}
                            {initialValues ? (domain + initialValues.qqcQuarterlyPdf).split('/').pop() : ''}

                        </div>

                    </div>

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
